.sphere_1{
  position: absolute;
  width: 415.87px;
  height: 415.87px;
  left: 1846.19px;
  top: 1076.81px;
  background: linear-gradient(161.03deg, rgba(130, 62, 165, 0.84) 14.57%, rgba(125, 190, 225, 0.6048) 86.76%, rgba(152, 212, 255, 0.84) 158.96%);
  transform: rotate(-169.54deg);
}

.sphere_2{
  box-sizing: border-box;
  position: absolute;
  width: 208.86px;
  height: 208.86px;
  left: 283.13px;
  top: 943.19px;
  background: radial-gradient(71.15% 71.15% at 14.61% 8.59%, rgba(117, 23, 211, 0.93) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(95.25% 79.03% at 18.77% 100%, #DE2DD3 0%, #9D27C5 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.7;
  transform: rotate(155.92deg);
}

.sphere_3{
  box-sizing: border-box;
  position: absolute;
  width: 93px;
  height: 93px;
  left: 1564.24px;
  top: 261.34px;
  background: radial-gradient(71.15% 71.15% at 14.61% 8.59%, rgba(117, 23, 211, 0.93) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(95.25% 79.03% at 18.77% 100%, #DE2DD3 0%, #9D27C5 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.7;
  transform: rotate(-90deg);
}

.test{
  position:relative;
  width:100%;
  height: 100vh
}