.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.button {
  background: radial-gradient(
        105.58% 20.13% at 100% 19.51%,
        #f7ebff 13.85%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        243.14% 76.54% at 169.08% 76.52%,
        #f6d9ff 25.79%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(97.49deg, #f6eaff -2.83%, #ffffff 66.82%),
    linear-gradient(181.01deg, #fff0f8 1.94%, #f2fbff 102.25%), #ffffff;
  border: 2px solid #5e14a8;
  box-shadow: 0px 12px 8px -11px rgba(101, 4, 145, 0.5);
  border-radius: 40px;
  opacity: 0.9;
  filter: drop-shadow(0px 0px 11px rgba(219, 68, 243, 0.6));

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #5e14a8;
  /* margin-top: 10px; */
  cursor: pointer;
}

.disabledButton {
  background: radial-gradient(
        105.58% 20.13% at 100% 19.51%,
        #f7ebff 13.85%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        243.14% 76.54% at 169.08% 76.52%,
        #f6d9ff 25.79%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(97.49deg, #f6eaff -2.83%, #ffffff 66.82%),
    linear-gradient(181.01deg, #fff0f8 1.94%, #f2fbff 102.25%), #ffffff;
  border: 2px solid #5e14a8;
  border-radius: 40px;
  color: #5e14a8;
  opacity: 0.5;
  filter: drop-shadow(0px 0px 11px rgba(219, 68, 243, 0.6));
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  /* margin-top: 10px; */
  cursor: not-allowed;
}

.button:active {
  box-shadow: 0px 12px 8px -11px rgba(101, 4, 145, 1);
  filter: drop-shadow(0px 0px 11px rgba(219, 68, 243, 1));
  transform: scale(0.98);
}

.button:hover {
  box-shadow: 0px 12px 8px -11px rgba(101, 4, 145, 1);
  filter: drop-shadow(0px 0px 11px rgba(219, 68, 243, 1));
}

.buttonClass {
  width: 100%;
}
