.exchangeContainer {
  display: flex;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 20px;
}
.exchangeContainerSM {
  justify-content: center !important;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  padding-right: 20px;
  padding-top: 8px;
}

.titleMobile {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  width: "112%";
  padding-bottom: 10px;
}

.exchangeItem {
  background: linear-gradient(
      170.98deg,
      rgba(50, 112, 205, 0.45) -12.36%,
      rgba(168, 66, 249, 0.5) 100.49%
    ),
    #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(9px);
  border-radius: 30px;
  color: #5e14a8;
  padding: 1px 5px;
  cursor: pointer;
  margin-right: 10px;
}

.exchangeItemMobile {
  background: linear-gradient(
      170.98deg,
      rgba(50, 112, 205, 0.45) -12.36%,
      rgba(168, 66, 249, 0.5) 100.49%
    ),
    #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(9px);
  border-radius: 30px;
  color: #5e14a8;
  padding: 0px 3px;
  margin-bottom: 10px;
  margin-top: 0px;
  cursor: pointer;
  margin-right: 10px;
  /* width: 39%; */
}

.exchangeItem:active {
  box-shadow: 0px 12px 8px -11px rgba(101, 4, 145, 1);
  filter: drop-shadow(0px 0px 11px rgba(219, 68, 243, 1));
  transform: scale(0.98);
}

.exchangeItem:hover {
  box-shadow: 0px 12px 8px -11px rgba(101, 4, 145, 1);
  filter: drop-shadow(0px 0px 11px rgba(219, 68, 243, 1));
}

.inlineFlux {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.inlineFluxSM {
  justify-content: center !important;
}

.inlineFlux img {
  width: 20px;
  margin: 0 5px;
}

.capClass {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
